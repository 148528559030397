@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
	--foreground-rgb: 0, 0, 0;
	--background-start-rgb: 240, 240, 240;
	--background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
	:root {
		--foreground-rgb: 0, 0, 0;
		--background-start-rgb: 240, 240, 240;
		--background-end-rgb: 255, 255, 255;
	}
}

body {
	color: rgb(var(--foreground-rgb));
	background: linear-gradient(to bottom, transparent, rgb(var(--background-end-rgb))) rgb(var(--background-start-rgb));
}

a {
	@apply text-blue-500;
}

hr {
	@apply my-1 border-2 border-gray-300;
}

code {
	@apply border border-yellow-200 whitespace-pre-line inline-block rounded-md bg-yellow-50;
}
pre > code {
	@apply block my-2 break-words mx-4 p-2;
}

@layer utilities {
	.text-balance {
		text-wrap: balance;
	}
}

.loading:after {
	overflow: hidden;
	display: inline-block;
	vertical-align: bottom;
	-webkit-animation: ellipsis steps(4, end) 900ms infinite;
	animation: ellipsis steps(4, end) 900ms infinite;
	content: '\2026';
	/* ascii code for the ellipsis character */
	width: 0px;
}

.marked-down p:first-child {
	@apply pt-0;
}

.marked-down p:last-child {
	@apply pb-0;
}

.marked-down p {
	@apply py-2;
}

.marked-down ol,
ul {
	@apply list-disc;
}

.marked-down li {
	@apply ml-4;
}

.marked-down strong {
	@apply font-bold;
}

@keyframes ellipsis {
	to {
		width: 14px;
	}
}

@-webkit-keyframes ellipsis {
	to {
		width: 14px;
	}
}
